import { faGithub, faLinkedinIn, faChrome } from '@fortawesome/free-brands-svg-icons';
import { faServer, faGraduationCap, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';

export const Introduction = {
	name: 'M. Angel Jacquel',
	email: 'ajacquel@devight-laboratory.ovh',
	website: 'cv.8e-couche.ovh',
	social_icons: [
		{
			link: 'https://github.com/Nikki-Devil',
			label: 'Github',
			icon: faGithub,
		},
		{
			link: 'https://fr.linkedin.com/in/angel-jacquel-760974212',
			label: 'LinkedIn',
			icon: faLinkedinIn,
		},
		{
			link: 'https://cv.devight-laboratory.ovh',
			label: 'Projet Devight Laboratory',
			icon: faChrome,
		},
		{
			link: 'https://cv.8e-couche.ovh',
			label: 'Projet 8e Couche',
			icon: faServer,
		},
		{
			link: 'https://bts.8e-couche.ovh',
			label: 'PortFolio BTS SIO SISR',
			icon: faGraduationCap,
		},
	]
}

export const WhatsNewAboutExperienceEducationSkills = [
  {
    title: "Actualité",
		details: [
			{
				name: "2022 naissance de la 8ème couche",
				detail: "Le serveur de la 8ème couche a été mis en place pour entreposer mon portfolio," +
				" partager des ressources avec ma classe et mes amis et pour me servir d'hébergeur de projets.",
			},
                        {
                                name: "2023 - R&D intense",
                                detail: "J'ai beaucoup expérimenté en 2023, réinstallé de nombreuses fois mes serveurs " +
                                "et corrigé nombres problèmes que je pouvais créer. Je les ai même optimisés à chaque installations du fait de mon " +
                                "apprentissage constant et de ma montée en compétences, me permettant de faire moi-même ce que je faisais au début avec des outils externes",
                        },
                        {
                                name: "Right now !",
                                detail: "A l'heure actuelle, les serveurs de la 8e-couche sont des serveurs essentiellement de services et d'hébergement de projets. " +
                                "Tout les projets 8e-couche sont testé sur le serveur du Devight-Laboratory avant d'être installé ici. " +
                                "La liste des services actifs actuellement :",
                        },
                        {
                                name: "RustDesk",
				reference_links: [
                                        {
                                                        link:"https://rustdesk.com",
                                                        logo: faChrome
                                        },
                                ]
                        },
                        {
                                name: "SFTP Privé - Ressources BTS SIO",
                        },
                        {
                                name: "Serveur de mail",
                                reference_links: [
                                        {
                                                        link:"mailto:ajacquel@devight-laboratory.ovh",
                                                        logo: faEnvelope
                                        },
                                ]
                        },
                        {
                                name: "Serveur de stockage/backup",
                        },
                        {
                                name: "Webmin",
                                reference_links: [
                                        {
                                                        link:"https://webmin.com",
                                                        logo: faGlobe
                                        },
                                ]
                        },
                        {
                                name: "Cockpit",
                                reference_links: [
                                        {
                                                        link:"https://cockpit-project.org",
                                                        logo: faGlobe
                                        },
                                ]
                        },
		]
	},
	{
		title: 'Autre projets',
		details: [
			{
				name: "Devight Laboratory",
				web_url: 'https://cv.devight-laboratory.ovh',
				reference_links: [
				]
			},
			{
				name: "Porte Folio BTS SIO SISR",
				web_url: 'https://bts.8e-couche.ovh',
				reference_links: [
				]
			},
                        {
                                name: "Maquette BTS - Infra Light-Snoop",
                                web_url: 'https://github.com/E5-MMGE/Schema/blob/main/E5%20-%20Schematic%20-%20Dark.png',
                                reference_links: [
                                ]
                        },
			{
				name: "Earth Country",
				web_url: 'https://github.com/EarthCountry-modpack',
				reference_links: [
				]
			},
		]
	},
	{
		title: 'À propos',
		details: [
			{

                                        major: "En 3ème année à l'ESGI en alternance, J'ai mis en place le serveur du Devight Laboratory ainsi que celui " +
                                        "de la 8ème couche. Je suis un passioné d'informatique, d'astrophysique et un grand joueur de Minecraft."
			},
		]
	},
	{
		title: "Experiences",
		details: [
                        {
                                name: "Axians",
                                web_url: 'https://www.axians.fr/',
                                major: "Administrateur Système et Réseau - Alternance",
                                date: 'Aout 2024 - Futur',
                                detail: null,
                        },
			{
				name: "Bioburger, chaine de burger bio.",
				web_url: 'https://www.bioburger.fr/',
				major: "Manager",
				date: 'Aout 2022 - Présent',
				detail: null,
			},
			{
				name: "KNCO, lunetier français.",
				web_url: 'https://knco.fr/',
				major: "Technicien informatique - Alternance",
				date: 'Oct 2022 - Août 2024',
				detail: null,
			},
			{
				name: "Etablissement Sainte Geneviève Courbevoie.",
				web_url: 'https://stgsp-courbevoie.fr/',
				major: "Techicien informatique",
				date: 'Sep 2021 - Feb 2022',
				detail: null,
			},
		]
	},
  {
    title: "Ecoles",
		details: [
                        {
                                name: "ESGI",
                                web_url: 'https://www.esgi.fr/',
                                major: "Systèmes, Réseaux et Cloud Computing",
                                date: '2024 - Futur',
                                detail: null,
                        },
			{
				name: "Fenelon Sup, Paris",
				web_url: 'https://www.fenelonsup.org/',
				major: "BTS SIO SISR",
				date: '2021 - 2024',
				detail: null,
			},
			{
				name: "Lycée Saint Joseph, Argenteuil",
				web_url: 'http://www.stjosephargenteuil.fr/',
				major: "Bac Général, équivalent de l'ancien S",
				date: '2019 - 2021',
				detail: null,
			}
		]
  },
  {
    title: "Skills, Technologies & Tools",
    skills: true
  }  
]

export const Skills = [
        'devicon-python-plain-wordmark colored',
        'devicon-windows8-original colored',
        'devicon-atom-plain-wordmark colored',
        'devicon-linux-plain',
        'devicon-debian-plain-wordmark colored',
        'devicon-ubuntu-plain-wordmark colored',
        'devicon-git-plain-wordmark colored',
        'devicon-github-original-wordmark',
        'devicon-android-plain-wordmark colored',
        'devicon-jira-plain colored',
        'devicon-markdown-original',
        'https://devicon.dev/',
]
